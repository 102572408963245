import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PageTemplate = ({ data }) => {
  const { name, slug, content } = data.contentfulPage
  const postNode = data.contentfulPage
  // console.log("printing")
  return (
    <Layout>
      <SEO title={`${name} - ${config.siteTitle}`} pagePath={slug} postNode={postNode} pageSEO />
      <Helmet>
        <title>{`${name} - ${config.siteTitle}`}</title>
      </Helmet>
      <div className="container blogPost">
        {/* <h1>{name}</h1> */}
        <div className="content"
          dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      name
      slug
      content {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PageTemplate
